.product-cont{
    width: 90%;
    margin: auto;
    display: flex;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 20px;
    overflow: hidden;
}
.product-cont>:nth-child(1){
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}
.product-cont>:nth-child(1)>img{
    width: 60%;
}
.product-cont>:nth-child(2){
    width: 40%;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    background-color: rgb(248, 248, 248);
    padding: 3rem;
    font-family: 'Hemi Head Rg',sans-serif;
    border-left: 3px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.p-title{
    font-size: 2.2rem;
}
.icon-cont{
    position: relative;
    display: flex;
    justify-content: center;
    height: 3.5rem;
}
.icon{
    display: flex;
    transform: rotate(-45deg);
    width: 4rem;
    height: 4rem;
    position: absolute;
    left: 0;
    fill:rgb(0, 253, 168);
}
.extend{
    width: 9.5rem;
    height: 3rem;
    background-color: rgb(0, 255, 170);
    position: absolute;
    left: .8rem;
    margin-top: .5rem;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.detaile-price{
    z-index: 5;
    position: absolute;
    top: 1rem;
    left: 1.8rem;
    color: rgb(248, 248, 248);
}
.detailes-category{
    background-color: rgba(213, 213, 213, 0.5);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 1rem;
}
.detailes-description{
    font-weight: normal;
    font-size: 1.5rem;
}
.detailes-button{
    font-family: 'Hemi Head Rg',sans-serif;
    width: fit-content;
    padding: 1rem 4rem;
    font-size: 1.3rem;
    border-radius: 10px;
    background-color: rgba(221, 0, 0, 0.568);
    border: 1px solid rgb(124, 0, 0);
    cursor: pointer;
}
@media screen and (max-width:800px) {
    .product-cont{
        flex-direction: column;
        width: 90%;
    }
    .product-cont>:nth-child(1){
        width: 100%;
        padding: 3rem 0;
    }
    .product-cont>:nth-child(1)>img{
        width: 40%;
    }
    .product-cont>:nth-child(2){
        width: 100%;
        border: none;
        border-top:3px solid rgba(0, 0, 0, .1) ;
        padding: 0;
    }
    .p-title{
        font-size: 1.8rem;
        margin:1rem 2rem 0 2rem;
    }
    .icon-cont{
        
        margin:0 3rem;
    }
    .detailes-description{
        width:85%;
        margin:0 2rem;
    }
    .detailes-button{
        width: 70%;
        max-width: 12rem;
        font-size: 1rem;
        margin:0 2rem 2rem 2rem;
        padding: 1em;
    }
    .detailes-category{
        margin:0 2rem;
        font-size: 1.5rem;
    }
}
@media screen and (max-width:400px) {
    .p-title{
        font-size: 1.2rem;
        margin:1rem 1rem 0 1rem;
    }
    .icon-cont{
        margin:0 1.8rem;
    }
    .detaile-price{
        font-size: 1rem;
        top: 1.15rem;
    }
    .icon{
        width: 3.3rem;
        height: 3.3rem;
        top: .1rem;
    }
    .extend{
        width: 7rem;
        height: 2.5rem;
    }
    .detailes-description{
        margin: 0 1rem;
        font-size: 1.1rem;
    }
    .detailes-button{
        margin: 0 1rem 2rem 1rem;
    }
    .detailes-category{
        margin: 0 1rem;
        font-size: 1rem;
    }
}