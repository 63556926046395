@font-face {
    font-family: 'Berserker';
    src: url('../../../fonts/Berserker/Berserker-Regular.woff2') format('woff2'),
        url('../../../fonts/Berserker/Berserker-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Hemi Head Rg';
    src: url('../../../fonts/HemiHead/HemiHeadRg-BoldItalic.woff2') format('woff2'),
        url('../../../fonts/HemiHead/HemiHeadRg-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
