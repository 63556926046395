.cards-container{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} 
@media screen and (max-width:1060px) {
    .cards-container{
        justify-content: center;
        gap: 1rem;
    } 
}