.Nav-cont{
    position: fixed;
    top: 0;
    padding: 2rem;
    width: 100%;
    background-color: rgb(248, 248, 248);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
}
.Nav-cont>div{
    width: 80%;
    margin: auto;

}
.header-navegate{
    font-family: 'Berserker',sans-serif;
    margin: 0;
    padding: 0;
    color: black;
    text-decoration: none;
}