.card{
    width: 20.5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 20px;
    margin-bottom: 1rem;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
}
@media screen and (max-width:1060px) {
    .card{
        margin: 0;
    } 
}
.product-navegate>:nth-child(1){
    height: 22rem;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.product-navegate>:nth-child(1)>img{
    height: 100%;
    width: 90%;
    margin: 1.5rem;
}
.content{
    display: flex;
    flex-direction: column;
    gap: .8rem;
    background-color: rgb(248, 248, 248);
    padding: 1rem;
    font-family: 'Hemi Head Rg',sans-serif;
    border-top: 1px solid rgba(0, 0, 0, .2);
}
.content>:nth-child(1){
    height: 4rem;
    font-size: 1rem;
    font-weight: 600;
}
.content>:nth-child(2){
    color: rgba(0, 0, 0, .6);
}
.content>:nth-child(3){
    color: rgba(0, 0, 0, .6);
}